#root {
  position: relative;
  z-index: 1;
}

.markdown-container a {
  color: #8a6bea;
}

.markdown-container hr {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
}

@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  40% {
    transform: translate3d(0, -15%, 0) scale3d(0.7, 1.5, 1); /* Negative value */
  }
  100% {
    transform: translate3d(0, -30%, 0) scale3d(1.5, 0.7, 1); /* Negative value */
  }
}
.jump:hover {
  transform-origin: 50% 50%;
  animation: jump 0.5s linear alternate infinite;
}

.reason {
  padding: 30px;
  background: linear-gradient(
    rgba(20, 20, 20, 0.539) 0%,
    rgba(14, 14, 14, 0.8) 100%
  );
  border-bottom-width: 0px;
  border-color: rgba(255, 255, 255, 0.1);
  border-left-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-top-width: 1px;
  border-radius: 8px;
}

.feature {
  padding: 20px 30px;
  --x-px: calc(var(--x) * 1px);
  --y-px: calc(var(--y) * 1px);
  background: linear-gradient(
    rgba(20, 20, 20, 0.539) 0%,
    rgba(14, 14, 14, 0.8) 100%
  );
  border-bottom-width: 0px;
  border-color: rgba(255, 255, 255, 0.08);
  border-left-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-top-width: 1px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.feature:before,
.feature:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: radial-gradient(
    100vh circle at var(--x-px) var(--y-px),
    #af9ce74f,
    transparent 40%
  );
}

.feature:before {
  z-index: 1;
}

.feature:after {
  opacity: 0;
  z-index: 2;
  transition: opacity 0.4s ease;
}

.feature:hover:after {
  opacity: 1;
}

.solanafeature {
  padding: 20px 30px;
  --x-px: calc(var(--x) * 1px);
  --y-px: calc(var(--y) * 1px);
  background: linear-gradient(
    rgba(20, 20, 20, 0.539) 0%,
    rgba(14, 14, 14, 0.8) 100%
  );
  border-bottom-width: 0px;
  border-color: rgba(255, 255, 255, 0.08);
  border-left-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-top-width: 1px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.solanafeature:before,
.solanafeature:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: radial-gradient(
    100vh circle at var(--x-px) var(--y-px),
    #a6e5cd4f,
    transparent 40%
  );
}

.solanafeature:before {
  z-index: 1;
}

.solanafeature:after {
  opacity: 0;
  z-index: 2;
  transition: opacity 0.4s ease;
}

.solanafeature:hover:after {
  opacity: 1;
}

.arrows {
  width: 30px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
}

.arrows path {
  stroke: #8a6bea;
  fill: transparent;
  stroke-width: 2px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.5;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.5;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}

.full-wh {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
}

.bg-animation {
  position: static;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#stars2 {
  position: fixed;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: transparent;
  box-shadow:
    1117px 1306px #f1f1f382,
    1078px 1783px #f1f1f382,
    1179px 1085px #f1f1f382,
    1145px 920px #f1f1f382,
    422px 1233px #f1f1f382,
    1954px 502px #f1f1f382,
    1192px 1308px #f1f1f382,
    540px 1239px #f1f1f382,
    1360px 552px #f1f1f382,
    89px 752px #f1f1f382,
    659px 1253px #f1f1f382,
    62px 517px #f1f1f382,
    1375px 1705px #f1f1f382,
    1343px 1511px #f1f1f382,
    1659px 1922px #f1f1f382,
    1560px 289px #f1f1f382,
    1362px 1799px #f1f1f382,
    1886px 1480px #f1f1f382,
    1718px 1885px #f1f1f382,
    824px 738px #f1f1f382,
    1060px 1370px #f1f1f382,
    1781px 1171px #f1f1f382,
    255px 273px #f1f1f382,
    1197px 120px #f1f1f382,
    213px 7px #f1f1f382,
    1226px 1920px #f1f1f382,
    859px 108px #f1f1f382;
  -webkit-animation: animStar 100s linear infinite;
  -moz-animation: animStar 100s linear infinite;
  -ms-animation: animStar 100s linear infinite;
  animation: animStar 100s linear infinite;
}

#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: transparent;
  box-shadow:
    1117px 1306px #f1f1f382,
    1078px 1783px #f1f1f382,
    1179px 1085px #f1f1f382,
    1145px 920px #f1f1f382,
    422px 1233px #f1f1f382,
    1954px 502px #f1f1f382,
    1192px 1308px #f1f1f382,
    540px 1239px #f1f1f382,
    1360px 552px #f1f1f382,
    89px 752px #f1f1f382,
    659px 1253px #f1f1f382,
    62px 517px #f1f1f382,
    1375px 1705px #f1f1f382,
    1343px 1511px #f1f1f382,
    1659px 1922px #f1f1f382,
    1560px 289px #f1f1f382,
    1362px 1799px #f1f1f382,
    1886px 1480px #f1f1f382,
    1718px 1885px #f1f1f382,
    824px 738px #f1f1f382,
    1060px 1370px #f1f1f382,
    1781px 1171px #f1f1f382,
    255px 273px #f1f1f382,
    1197px 120px #f1f1f382,
    213px 7px #f1f1f382,
    1226px 1920px #f1f1f382,
    859px 108px #f1f1f382;
}

@-webkit-keyframes animStar {
  from {
    -webkit-transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes animStar {
  from {
    -moz-transform: translateY(0px);
  }
  to {
    -moz-transform: translateY(-2000px);
  }
}

@-ms-keyframes animStar {
  from {
    -ms-transform: translateY(0px);
  }
  to {
    -ms-transform: translateY(-2000px);
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

.wallet-adapter-dropdown {
  display: flex;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: transparent;
}

.wallet-adapter-dropdown .wallet-adapter-button {
  height: unset;
  width: 100% !important;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.48);
  color: #f9fafb;
  background-color: transparent;
  font-family: inherit;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  color: #f9fafb;
  font-family: Avenir, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 4px;
  -webkit-transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid currentColor;
  color: inherit;
  border-color: currentColor;
  width: 100% !important;
  color: #f9fafb;
  text-transform: capitalize;
  font-weight: 500;
  box-shadow: none;
  margin-bottom: 12px;
}

.wallet-adapter-dropdown .wallet-adapter-button:hover {
  display: flex;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.48);
  color: #f9fafb;
  background-color: transparent;
  font-family: inherit;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  color: #f9fafb;
  font-family: Avenir, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 100%;
  padding: 5px 15px;
  border-radius: 4px;
  -webkit-transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid currentColor;
  color: inherit;
  border-color: currentColor;
  width: 100% !important;
  color: #f9fafb;
  text-transform: capitalize;
  font-weight: 500;
  box-shadow: none;
  margin-bottom: 12px;
}
